var render = function () {
  var _vm$boardInfo, _vm$boardInfo$departm, _vm$boardInfo$departm2, _vm$boardInfo2, _vm$boardInfo2$milest, _vm$boardInfo2$milest2, _vm$boardInfo3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('b-row', [_c('b-col', [_vm.boardInfo ? [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron pl-0"
  }, [_c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowDepartmentDetails
    }
  }, [_vm._v(" Dept: " + _vm._s((_vm$boardInfo = _vm.boardInfo) === null || _vm$boardInfo === void 0 ? void 0 : (_vm$boardInfo$departm = _vm$boardInfo.department) === null || _vm$boardInfo$departm === void 0 ? void 0 : (_vm$boardInfo$departm2 = _vm$boardInfo$departm.data) === null || _vm$boardInfo$departm2 === void 0 ? void 0 : _vm$boardInfo$departm2.name) + " ")]), _c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowMilestones
    }
  }, [_vm._v(" Milestone: " + _vm._s((_vm$boardInfo2 = _vm.boardInfo) === null || _vm$boardInfo2 === void 0 ? void 0 : (_vm$boardInfo2$milest = _vm$boardInfo2.milestone) === null || _vm$boardInfo2$milest === void 0 ? void 0 : (_vm$boardInfo2$milest2 = _vm$boardInfo2$milest.data) === null || _vm$boardInfo2$milest2 === void 0 ? void 0 : _vm$boardInfo2$milest2.title))]), _c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowPage
    }
  }, [_vm._v(" " + _vm._s((_vm$boardInfo3 = _vm.boardInfo) === null || _vm$boardInfo3 === void 0 ? void 0 : _vm$boardInfo3.title))])], 1)] : _vm._e()], 2), _c('b-col', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.LEAD_STATUS_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1 btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)])])], 1), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$column2, _props$row6, _props$row7, _props$row8;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_title' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.title))])])] : _vm._e(), props.column.field === 'format_type' ? _c('span', [!(props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.is_backlog) ? [_c('b-badge', {
          attrs: {
            "variant": props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.is_final ? 'light-danger' : 'light-primary'
          }
        }, [_vm._v(" " + _vm._s(props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && _props$row4.is_final ? "Final" : "General") + " ")])] : props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.is_backlog ? [_c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" Initial ")])] : _vm._e()], 2) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_task_sub_task_count' ? [_c('div', {
          staticClass: "mt-1"
        }, [_c('b', [_vm._v("Task: ")]), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.task_count))])], 1), _c('div', {
          staticClass: "mt-1"
        }, [_c('b', [_vm._v("Sub Task: ")]), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.sub_task_count))])], 1)] : _vm._e(), props.column.field === 'action' && !(props !== null && props !== void 0 && (_props$row8 = props.row) !== null && _props$row8 !== void 0 && _props$row8.is_backlog) ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.LEAD_STATUS_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAD_STATUS_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-phase",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Phase Information ' : 'Create Phase',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "milestoneTaskBoardPhase"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneTaskBoardPhaseForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Phase Title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Type *",
      "label-for": "type"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_final",
      "vid": "is_final",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_final",
            "placeholder": "Choose a Type",
            "options": _vm.typeValueOption,
            "reduce": function reduce(country) {
              return country.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectTypeValue,
            callback: function callback($$v) {
              _vm.selectTypeValue = $$v;
            },
            expression: "selectTypeValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }